import React from "react";
import TimeAgo from "timeago-react";
import Config from "./Config";
import {AmplitudeSession} from "./Amplitude";

interface SessionState {
    lastTimediff: number;
    session: AmplitudeSession;
    selected: boolean;
    onClick: () => void;
}

export default function Session(state: SessionState) {
    let ts = state.session.updated - state.lastTimediff;
    let icon = Config.knownKeys[state.session.apiKey] ?  '/icons/' + Config.knownKeys[state.session.apiKey] + '.png' : '/icons/unknown.png';
    let lastEvent = 'last event';
    if (state.session.events && state.session.events.length > 0) {
        let last = state.session.events[state.session.events.length - 1];
        lastEvent = last.event_type;
    }

    return <div className={`session ${state.selected ? 'selected' : ''}`} onClick={() => state.onClick()}>
        <div className="uuid">
            {state.session.uuid ? state.session.uuid : state.session.id}
        </div>
        <img src={icon} alt="" />
        <div className="useragent">
            {state.session.userAgent ? `${state.session.userAgent.client} ${state.session.userAgent.version}` : ''}
        </div>
        <div className="device">
            {state.session.device ? `${state.session.device.manufacturer} ${state.session.device.model}` : ''}
        </div>
        <div className="lastevent">
            {lastEvent}
        </div>
        <div className="date">
            <TimeAgo datetime={ts} />
        </div>
    </div>
}
