import PropertyList from "./PropertyList";
import React from "react";
import TimeAgo from "timeago-react";
import {AmplitudeEvent} from "./Amplitude";

interface EventListState {
    events: AmplitudeEvent[];
    lastTimediff: number;
}

export default function EventList(state: EventListState) {
    let events = state.events;
    if (!events) {
        return <div className="eventlist" />;
    }
    // console.log('events', events);
    let result = [];
    for (let i = events.length - 1; i >= 0; i--) {
        let event = events[i];

        let ts = event.timestamp - state.lastTimediff;

        result.push(
            <div key={event.sequence_number} className="event">
                <TimeAgo className="date" datetime={ts} />
                <div className="event-name">{event.event_type}</div>
                {
                    event.event_type === '$identify' ?
                        <PropertyList properties={event.user_properties['$set'] ?? {}} />
                        :
                        <PropertyList properties={event.event_properties} />
                }
            </div>
        );
    }
    return <div className="eventlist">{result}</div>;
}
