interface ConfigType {
    github: {
        client_id: string;
    };
    knownKeys: {
        [key: string]: string;
    };
}

let Config: ConfigType = {
    github: {
        client_id: 'bbefe9cb2e73adfa4fc9'
    },
    knownKeys: {
        '401b84a1f9a3f7368cc36c9d93a5d132': 'pregnancy',
        '45f2aaf36c3cf0aada173eb5872f8dab': 'babycare',
        '351091a3ba1d3b9bcdcb418fff20e629': 'sibreath',
        'e20c1b065533968d939b8a33b5550372': 'clover',
        '6e14e84302ac3f6025f409985d0c06d4': 'shoppinglist',
        '0019968ca04088df672348b06068d613': 'wachanga',
        'ios-pregnancy': 'pregnancy',
        'ios-babycare': 'babycare',
        'ios-sibreath': 'sibreath',
        'ios-clover': 'clover',
        'ios-shoppinglist': 'shoppinglist',
        'ios-wachanga': 'wachanga'
    }
};

export default Config;
