import EventList from "./EventList";
import React from "react";
import {AmplitudeSession} from "./Amplitude";

interface EventsState {
    session?: AmplitudeSession;
    lastTimediff: number;
}

export default function Events(state: EventsState) {
    return <div className="events">
        <EventList events={state.session?.events ?? []} lastTimediff={state.lastTimediff} />
    </div>
}
