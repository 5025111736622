import React, {useEffect, useRef, useState} from 'react';
import './App.css';
import Events from "./Events";
import Session from "./Session";
import {AmplitudeEvent, AmplitudeSessions} from "./Amplitude";
import Cookies from 'js-cookie';
import Client, {ClientState} from "./client/Client";
import Config from "./Config";

let client = new Client();

function App() {
  const [authorized, setAuthorized] = useState<false | undefined | {login: string, avatar?: string}>(undefined);
  const [firstConnect, setFirstConnect] = useState(true);
  const [appState, setAppState] = useState('Подключение...');

  const [sessions1, setSessions] = useState<AmplitudeSessions>({});
  const [lastTimediff, setLastTimediff] = useState(0);
  const [selectedSession, setSelectedSession] = useState('');
  const ws = useRef(client);
  const realSessions = useRef<AmplitudeSessions>({});

  useEffect(() => {
    console.log('USE EFFECT');

    let sessions = realSessions.current;

    let sid = Cookies.get('session');
    if (!sid) {
      setAuthorized(false);
    }
    else {
      setAppState('Подключение...');

      ws.current.on('state', (state) => {
        switch (state) {
          case ClientState.connecting: {
            setAppState('Подключение...');
            break;
          }
          case ClientState.closed: {
            setAppState('Отключено');
            break;
          }
          case ClientState.error: {
            setAppState('Ошибка подключения');
            break;
          }
          case ClientState.authorization: {
            setAppState('Инициализация...');
            break;
          }
          case ClientState.reconnect: {
            setAppState('Переподключение...');
            break;
          }
          case ClientState.online: {
            setAppState('Подключено');
            break;
          }
          case ClientState.watch: {
            setAppState('Live');
            setFirstConnect(false);
            break;
          }
          case ClientState.denied: {
            setAppState('Нет доступа');
          }
        }
      });

      ws.current.on('auth_required', () => {
        Cookies.remove('session');
        setAuthorized(false);
        setFirstConnect(true);
      });

      ws.current.on('authorized', (info) => {
        setAuthorized(info);
      });

      ws.current.on('sessions', (value) => {
        setLastTimediff(ws.current.timeDiff);

        sessions = value;
        setSessions(sessions);

        let sorted = Object.entries(sessions)
            .sort(([,a], [,b]) => { return a.updated < b.updated ? 1 : -1 });
        if (sorted.length > 0) {
          setSelectedSession('' + sorted[0][1].id)
        }
      });

      ws.current.on('event', (session, event) => {
        let oldEvents: AmplitudeEvent[] = [];
        if (sessions[session.id] && sessions[session.id].events) {
          oldEvents = sessions[session.id].events!.slice();
        }
        oldEvents.push(event);

        session.events = oldEvents;

        sessions = {...sessions};
        sessions[session.id] = session;

        setSessions(sessions);
      });

      ws.current.on('session', (session) => {
        sessions = {...sessions};
        sessions[session.id] = session;
        setSessions(sessions);
      });

      ws.current.open(sid);

      return () => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        ws.current.close();
      };
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function onExit() {
    ws.current.exit();
    Cookies.remove('session');
    setAuthorized(false);
    setFirstConnect(true);
  }

  if (firstConnect || !authorized) {
    return <div className="unauthorized">

      {
        authorized === false ?
          <a href={`https://github.com/login/oauth/authorize?client_id=${Config.github.client_id}`} className="centered">
            <div className="signin-icon">
              <svg height="32" width="32" viewBox="0 0 16 16" style={{fill: 'white'}}>
                <path fillRule="evenodd" d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38
          0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01
          1.08.58 1.23.82.72 1.21 1.87.87
          2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12
          0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08
          2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0
          .21.15.46.55.38A8.013 8.013 0 0016 8c0-4.42-3.58-8-8-8z"/>
              </svg>
            </div>
            <div className="signin-text">
              Войти через GitHub
            </div>
          </a>
          :
          <div className="centered connecting-state">
            {appState}
          </div>
      }

    </div>
  }

  return (
    <div className="App">
          <div className="sessions">
            <div className="status">
              <span className="gh-user"><img className="gh-avatar" src={authorized.avatar} alt={authorized.login} /> <span className="gh-login">{authorized.login}</span></span>
              <a className="gh-exit" onClick={onExit}>
                <svg height="16" width="16" viewBox="0 0 24 24"><path d="M14.8,12l3.6-3.6c0.8-0.8,0.8-2,0-2.8c-0.8-0.8-2-0.8-2.8,0L12,9.2L8.4,5.6c-0.8-0.8-2-0.8-2.8,0   c-0.8,0.8-0.8,2,0,2.8L9.2,12l-3.6,3.6c-0.8,0.8-0.8,2,0,2.8C6,18.8,6.5,19,7,19s1-0.2,1.4-0.6l3.6-3.6l3.6,3.6   C16,18.8,16.5,19,17,19s1-0.2,1.4-0.6c0.8-0.8,0.8-2,0-2.8L14.8,12z" /></svg>
              </a>
              <span className="connecting-state">{appState}</span>
            </div>
            {Object.keys(sessions1).length === 0 ? 'No active sessions' : ''}
            {Object.entries(sessions1)
                .sort(([, a], [, b]) => { return a.updated < b.updated ? 1 : -1 })
                .map(([sessionId, session]) => {
                  return <Session key={sessionId} onClick={() => setSelectedSession(sessionId)} session={session} selected={sessionId === selectedSession} lastTimediff={lastTimediff} />
                })
            }
          </div>
          <Events session={sessions1[selectedSession]}  lastTimediff={lastTimediff} />
    </div>
  );
}




export default App;
