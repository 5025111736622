import React from "react";

interface PropertyListState {
    properties: {
        [key: string]: any;
    }
}

export default function PropertyList(state: PropertyListState) {
    return <table className="properties">
        <tbody>
        {Object.entries(state.properties).map(([prop, value]) => {
            let plainValue;
            if (typeof(value) === 'string' || typeof(value) === 'boolean' || typeof(value) === 'number') {
                plainValue = '' + value;
            }
            else if (value === null) {
                plainValue = 'null';
            }
            else {
                plainValue = JSON.stringify(value);
            }

            return <tr key={prop} className="property">
                <td className="property-name">{prop}</td>
                <td className="property-value">{plainValue}</td>
            </tr>
        })}
        </tbody>
    </table>
}
