import {EventEmitter} from "events";
import {AmplitudeEvent, AmplitudeSession, AmplitudeSessions} from "../Amplitude";

export enum ClientState {
    closed,
    connecting,
    authorization,
    denied,
    online,
    watch,
    reconnect,
    error
}

declare interface Client {
    on(event: string, listener: Function): this;

    on(event: "state", listener: (state: ClientState) => void): {};
    emit(event: "state", state: ClientState): boolean;

    on(event: "auth_required", listener: () => void): {};
    emit(event: "auth_required"): boolean;

    on(event: "authorized", listener: (info: {login: string, url: string}) => void): {};
    emit(event: "authorized", state: {login: string, url: string}): boolean;

    on(event: "sessions", listener: (sessions: AmplitudeSessions) => void): {};
    emit(event: "sessions", sessions: AmplitudeSessions): boolean;

    on(event: "event", listener: (session: AmplitudeSession, event: AmplitudeEvent) => void): {};
    emit(event: "event", session: AmplitudeSession, amplitudeEvent: AmplitudeEvent): boolean;

    on(event: "session", listener: (session: AmplitudeSession) => void): {};
    emit(event: "session", session: AmplitudeSession): boolean;
}

class Client extends EventEmitter {
    private socket?: WebSocket;
    private cmdId: number = 0;
    private requests: {
        [key: string]: {
            resolve: (result: any) => void;
            reject: (error: any) => void;
        }
    } = {};
    private sid: string = '';
    private _state = ClientState.closed;
    private connectTry = 0;

    timeDiff: number = 0;

    set state(value: ClientState) {
        this._state = value;
        this.emit('state', value);
    }

    get state() {
        return this._state;
    }

    private connect() {
        // let socket = new WebSocket(`wss://localhost/api`);
        // let socket = new WebSocket(`ws://localhost:9999/api`);
        let socket = new WebSocket(`${document.location.protocol === 'https:' ? 'wss' : 'ws'}://${document.location.host}/api`);

        if (this.socket) {
            this.socket.close();
        }
        this.socket = socket;

        let _onOpen = () => this.onOpen();
        let _onError = (e: Event) => this.onError(e);
        let _onMessage = (message: MessageEvent<any>) => this.onMessage(message);
        let _onClose = (e: CloseEvent) => {
            // cleanup
            socket.removeEventListener('open', _onOpen);
            socket.removeEventListener('error', _onError);
            socket.removeEventListener('close', _onClose);
            socket.removeEventListener('message', _onMessage);
            this.onClose(e);
        };

        socket.addEventListener('open', _onOpen);
        socket.addEventListener('error', _onError);
        socket.addEventListener('close', _onClose);
        socket.addEventListener('message', _onMessage);

        if (this.state !== ClientState.reconnect) {
            this.state = ClientState.connecting;
        }
    }

    private reconnect() {
        this.connectTry++;
        console.log('try', this.connectTry);
        let retryAfter = 2000;
        if (this.connectTry > 20) {
            retryAfter = 300000;
        }
        else if (this.connectTry > 10) {
            retryAfter = 30000;
        }
        else if (this.connectTry > 3) {
            retryAfter = 5000;
        }

        setTimeout(() => {
            this.state = ClientState.reconnect;
            setTimeout(() => {
                this.connect();
            }, retryAfter - 1000);
        }, 1000);
    }

    private onOpen() {
        console.log('ws connected');
        this.connectTry = 0;
        this.state = ClientState.authorization;
    }

    private onError(e: Event) {
        console.log('ws error', e);
        this.state = ClientState.error;
    }

    private onClose(e: CloseEvent) {
        console.log('ws close', e);
        if (this.state !== ClientState.closed) {
            this.reconnect();
        }

        if (this.state !== ClientState.error) {
            this.state = ClientState.closed;
        }

        this.socket = undefined;
    }

    private onMessage(message: MessageEvent<any>) {
        console.log('Message', message.data);
        if (!this.socket) {
            return;
        }

        try {
            let request = JSON.parse(message.data);
            if (!request.cmd || !request.data) {
                return;
            }

            if (request.timestamp) {
                this.timeDiff = new Date().getTime() - request.timestamp;
            }

            if (request.id) {
                let response = this.requests['r' + request.id];
                if (response) {
                    if (request.cmd === 'error') {
                        response.reject(request.data);
                    }
                    else {
                        response.resolve(request.data);
                    }
                    delete this.requests['r' + request.id];
                }
                return;
            }

            switch (request.cmd) {
                case 'hello': {
                    this.onHello(request.data);
                    break;
                }

                case 'sessions': {
                    this.onSessions(request.data);
                    break;
                }

                case 'session': {
                    this.onSession(request.data);
                    break;
                }

                case 'event': {
                    this.onEvent(request.data);
                    break;
                }
            }
        }
        catch {

        }
    }

    open(sid: string) {
        this.sid = sid;

        this.connect();
    }

    exit() {
        this.request('exit')
            .then(() => {
                this.close();
            })
            .catch(() => {
                this.close();
            });
    }

    close() {
        this.state = ClientState.closed;
        this.socket?.close();
        this.socket = undefined;
    }

    private request(command: string, payload: any = {}): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.cmdId++;
            this.socket?.send(JSON.stringify({cmd: command, id: this.cmdId, data: payload}));
            this.requests['r' + this.cmdId] = {
                resolve: resolve,
                reject: reject
            }
        });
    }

    private onHello(payload: any) {
        console.log('hello');

        if (payload.version !== '2') {
            console.log('Unsupported backend version');
            this.socket!.close();
            return;
        }

        console.log('try auth');
        this.request('auth', {session: this.sid})
            .then((res) => {
                console.log('ws response', res);
                switch (res.result) {
                    case 'authorized': {
                        this.state = ClientState.online;
                        this.emit('authorized', res.info);
                        this.startWatch();
                        break;
                    }
                    case 'expired': {
                        this.state = ClientState.closed;
                        this.close();

                        this.emit('auth_required');
                        break;
                    }
                    case 'denied': {
                        this.state = ClientState.denied;
                        break;
                    }
                }

            })
            .catch((err) => {
                console.log('ws response error', err);
            });
    }

    private startWatch() {
        this.request('watch')
            .then(() => {
                console.log('start watching');
                this.state = ClientState.watch;
            });
    }

    private stopWatch() {
        this.request('watch', {stop: true})
            .then(() => {
                console.log('stop watching');
                this.state = ClientState.online;
            });
    }

    private onSessions(payload: any) {
        if (!payload) {
            return;
        }

        this.emit('sessions', payload);
    }

    private onSession(payload: any) {
        if (!payload) {
            return;
        }

        let session = payload.session as AmplitudeSession;

        if (!session || !session.id) {
            return;
        }

        this.emit('session', session);
    }

    private onEvent(payload: any) {
        if (!payload) {
            return;
        }

        let session = payload.session as AmplitudeSession;
        let event = payload.event as AmplitudeEvent;

        if (!session || !event || !session.id) {
            return;
        }

        this.emit('event', session, event);
    }
}

export default Client;
